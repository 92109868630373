var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-row', [_c('b-col', [_vm.showAddButton ? _c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Add mash"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Create ")], 1)], 1) : _vm._e()], 1)], 1), _c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.dataset)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-dark btn-sm",
          attrs: {
            "title": "Preview"
          },
          on: {
            "click": function click($event) {
              return _vm.openPreviewModal(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "search"
          }
        })], 1), +props.row['Amount Mashed Remaining'] > 0 ? _c('button', {
          staticClass: "btn btn-warning btn-sm",
          attrs: {
            "title": "New fermentation"
          },
          on: {
            "click": function click($event) {
              return _vm.createFermentation(props.row.ID);
            }
          }
        }, [_c('strong', [_vm._v("F")])]) : _vm._e(), _c('button', {
          staticClass: "btn btn-primary btn-sm",
          attrs: {
            "title": "View"
          },
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-success btn-sm",
          attrs: {
            "title": "Edit"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          attrs: {
            "title": "Delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)])]);
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  })]), _c('b-modal', {
    ref: "mash-preview-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.mashPreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openMashInNewWindow(_vm.mashPreviewModal.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.closePreviewModal();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "70vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('MashSubmissionForm', {
    attrs: {
      "id": _vm.mashPreviewModal.id,
      "action": "preview"
    }
  })], 1), _c('hr')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }