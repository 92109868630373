<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="mashing"
              :load-dictionaries="loadDictionaries"
              :show-labels="false"
              :filters="filteringPanel.filters"
              @search="getData"
              @change="filterData"
              :loaded="filteringPanel.loaded"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-row v-show="dataTable.visible">
          <b-col>
            <mashing-table ref="mashing" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import MashingTable from "@/views/Production/Mashing/MashingTable.vue";
export default {
  name: "Mashing",
  components: {
    MashingTable
  },
  data: function() {
    return {
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "select",
            dataType: "string",
            tooltip: "Base product name",
            title: "Base Product",
            name: "mashing_base_product",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },

          {
            type: "daterange",
            dataType: "datetime",
            title: "Date Started",
            tooltip: "Date Started",
            defaultRange: "Last 12 months",
            name: "mashing_date_start"
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },

    async loadDictionaries() {
      let self = this;

      const baseproducts = async () => {
        let response = await this.$api.get("dictionaries/base-products");
        self.filteringPanel.filters.find(
          f => f.name === "mashing_base_product"
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      Promise.all([baseproducts()]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    filterData: function(e) {
      //let self = this;

      this.filteringPanel.selected = e;
    },
    getData() {
      //let self = this;

      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        base_products: f.base_product ? f.base_product.map(i => i.label) : [],
        mashing_date_start: f.mashing_date_start
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.$refs["mashing"].getData(payload);
    }
  },
  watch: {}
};
</script>

<style></style>
